import React, { useState, useEffect } from 'react'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppTextArea } from 'components/Common/AppFormFields/AppTextArea/AppTextArea.component'

interface IAppRegeneratePlanPopup {
  isOpen: boolean
  onSubmit: (feedback: string) => void
  onCancel: () => void
  goalName: string
}

const AppRegeneratePlanPopup: React.FC<IAppRegeneratePlanPopup> = ({
  isOpen,
  onSubmit,
  onCancel,
  goalName,
}) => {
  const [feedback, setFeedback] = useState<string>('')
  useEffect(() => {
    if (isOpen) {
      setFeedback('')
    }
  }, [isOpen])

  const handleCancel = () => {
    onCancel()
  }

  return isOpen ? (
    <AppModalBox
      title={'Regenerate "' + goalName + '" Plan'}
      bgColor="bg-background-transparentBg"
      onClose={handleCancel}
    >
      <div className="my-3 rounded-lg p-5">
        <div className="mb-4">
          <AppTextArea
            label={`Feedback on the plan of "${goalName}"`}
            onChange={(e) => {
              setFeedback(e.target.value)
            }}
            placeholder="Please provide feedback on the plan"
            rows={4}
          />
        </div>
        <div className="flex justify-end gap-4">
          <AppButton
            onClick={() => onSubmit(feedback)}
            size="sm"
            variant="primary"
            label="Regenerate Plan"
          />
        </div>
      </div>
    </AppModalBox>
  ) : null
}

export default AppRegeneratePlanPopup
