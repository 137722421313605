import React from 'react'

import { AppAlertIcon } from 'components/Common/AppSvgIcons/AppAlertIcon'
import {
  AppFieldWrapper,
  AppErrorWrapper,
  AppFieldParentWrapper,
  AppErrorMessageWrapper,
  AppLabelWrapper,
  AppTextAreaWrapper,
} from 'components/Common/AppFormFields/AppCommonStyles/AppFormField.style'
import appColors from 'appColors'

interface IconProps {
  color?: string
}

export interface IAppTextAreaProps {
  value?: string
  error?: string
  label?: string
  disabled?: boolean
  placeholder?: string
  rows: number
  onChange?: (value) => void
  Icon?: React.ComponentType<IconProps>
  onBlur?: (value) => void
}

export const AppTextArea: React.FC<IAppTextAreaProps> = ({
  value,
  label,
  disabled,
  onChange,
  onBlur,
  error = '',
  placeholder,
  rows = 5,
}) => {
  return (
    <div className="w-full">
      {label && <AppLabelWrapper>{label}</AppLabelWrapper>}
      <AppFieldParentWrapper>
        <AppFieldWrapper size="lg" error={!!error} disabled={!!disabled}>
          <AppTextAreaWrapper
            value={value}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            placeholder={placeholder || 'Placeholder'}
            rows={rows}
          />
        </AppFieldWrapper>
        {error && <AppErrorMessage error={error} />}
      </AppFieldParentWrapper>
    </div>
  )
}

export const AppErrorMessage = ({ error }) => {
  return (
    <AppErrorWrapper>
      <AppAlertIcon color={appColors['app-red']['70']} />
      <AppErrorMessageWrapper>{error}</AppErrorMessageWrapper>
    </AppErrorWrapper>
  )
}
