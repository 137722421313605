import React, { useMemo } from 'react'
import { Control, Controller, FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form'

import { FIELD_NAME, LABEL, TOOLTIP_TEXT } from 'config'
import { IOptionsItem } from 'types'

import {
  BUSINESS_STRUCTURE,
  registeredBusinessStructureV2,
  resetFields,
} from 'components/MonthlyAttestation/utils'
import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { AppCheckBox } from 'components/Common/AppFormFields/AppCheckBox/AppCheckBox.component'
import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'

interface IRegisterBusinessFields {
  control: Control<FieldValues>
  errors: FieldErrors<FieldValues>
  setValue: UseFormReturn['setValue']
  registerBusinessStructureOptions: Array<IOptionsItem>
  watch: (name: string) => FieldValues[keyof FieldValues]
  clearErrors: (name?: keyof FieldValues) => void
}
export const AppRegisterBusinessFields: React.FC<IRegisterBusinessFields> = ({
  errors,
  watch,
  control,
  setValue,
  registerBusinessStructureOptions,
  clearErrors,
}) => {
  const registeredBusinessValue = watch(BUSINESS_STRUCTURE.REGISTER_BUSINESS_OPTION_ID)
  const hasEIN = watch('hasEIN')
  const hasRegisterBusinessStructure = watch('hasRegisterBusinessStructure')

  const isDisabled = useMemo(() => {
    return (
      registeredBusinessValue === registeredBusinessStructureV2.noBusinessOptionId ||
      registeredBusinessValue === false ||
      hasRegisterBusinessStructure === false
    )
  }, [registeredBusinessValue, hasRegisterBusinessStructure])

  return (
    <>
      <div className="w-full my-4">
        <Controller
          name={FIELD_NAME.REGISTER_BUSINESS_STRUCTURE_OPTION_ID}
          control={control}
          render={({ field }) => (
            <AppSelectField
              toolTipText={TOOLTIP_TEXT.REGISTER_BUSINESS_STRUCTURE}
              size="md"
              {...field}
              label={`Business Structure${hasRegisterBusinessStructure ? ' *' : ''}`}
              options={registerBusinessStructureOptions?.filter((obj) =>
                obj.industry.includes('attestation')
              )}
              onChange={(e) => {
                if (e.value === registeredBusinessStructureV2.noBusinessOptionId) {
                  resetFields(setValue)
                  clearErrors('yearEstablished')
                  setValue('yearEstablished', '')
                  clearErrors('EIN')
                  setValue('EIN', '')
                }
                field.onChange(e.value)
              }}
              placeholder={`Limited Liability Company`}
              errors={
                (errors[FIELD_NAME.REGISTER_BUSINESS_STRUCTURE_OPTION_ID]?.message as string) || ''
              }
              disabled={hasRegisterBusinessStructure === false}
            />
          )}
        />

        {registeredBusinessValue === registeredBusinessStructureV2.otherBusinessOptionId && (
          <div className="mt-4">
            <Controller
              name="otherRegisteredBusinessStructure"
              control={control}
              render={({ field }) => (
                <AppTextField
                  size="md"
                  type="text"
                  placeholder="Enter Other Register Business Structure"
                  label="Other Registered Business Structure *"
                  error={errors.otherRegisteredBusinessStructure?.message as string}
                  {...field}
                  disabled={isDisabled}
                />
              )}
            />
          </div>
        )}
        <div className="pl-1 mt-4">
          <Controller
            name="hasRegisterBusinessStructure"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <AppCheckBox
                id="hasRegisterBusinessStructure"
                label={'I don’t have a Business Structure'}
                value={field.value === false}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  const newValue = !isChecked
                  field.onChange(newValue)
                  setValue('registerBusinessStructureOptionId', '')
                  setValue('hasEIN', newValue)
                  setValue('yearEstablished', '')
                  setValue('EIN', '')
                  clearErrors('registerBusinessStructureOptionId')
                  clearErrors('yearEstablished')
                  clearErrors('EIN')
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full my-4">
        <Controller
          name="EIN"
          control={control}
          render={({ field }) => (
            <AppTextField
              toolTipText={TOOLTIP_TEXT.EIN}
              size="md"
              type="text"
              mask="99-9999999"
              label={`${LABEL.EIN}${hasEIN && !isDisabled ? ' *' : ''}`}
              placeholder="XX-XXXXXXX"
              error={errors.EIN?.message as string}
              {...field}
              disabled={hasEIN === false || isDisabled}
            />
          )}
        />
        <div className="pl-1 mt-2">
          <Controller
            name="hasEIN"
            control={control}
            defaultValue={true}
            render={({ field }) => (
              <AppCheckBox
                id="hasEIN"
                label={'I don’t have an EIN'}
                disabled={hasRegisterBusinessStructure === false}
                value={field.value === false}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  const newValue = !isChecked
                  field.onChange(newValue)
                  setValue('EIN', '')
                  clearErrors('EIN')
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full my-2">
        <Controller
          name="yearEstablished"
          control={control}
          render={({ field }) => (
            <AppTextField
              size="md"
              type="text"
              placeholder="MM/YYYY"
              label={`Date Established ${!isDisabled ? '*' : ''}`}
              error={errors.yearEstablished?.message as string}
              {...field}
              disabled={isDisabled}
              mask="99/9999"
            />
          )}
        />
      </div>
    </>
  )
}
