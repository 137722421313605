import React, { useState } from 'react'
import { AppCheckBox } from '../AppFormFields/AppCheckBox/AppCheckBox.component'
import { Divider } from 'components/AppGoalCenter/AppGoalCenter.style'
import { AppToolTip } from '../AppToolTip/AppToolTip.component'
import { AppToolTipIcon } from '../AppToolTip/AppToolTipIcon'
import AppUpArrowIcon from '../AppSvgIcons/AppUpArrowIcon'
import AppDownArrowIcon from '../AppSvgIcons/AppDownArrowIcon'
import { AppBadge } from '../AppBadge/AppBadge.component'

type FilterOption = {
  label: string
  value: string | number
  toolTipText?: string
  disabled?: boolean
  hideOption?: boolean
}

type FilterCategory = {
  title: string
  label: string
  toolTipText?: string
  options: FilterOption[]
  disabled?: boolean
}

type FiltersProps = {
  value: Record<string, (string | number)[]>
  categories: FilterCategory[]
  onFilterChange: (
    selectedFilters: Record<string, (string | number)[]>,
    filterCount: number
  ) => void
}

export const AppFilterMenu: React.FC<FiltersProps> = ({ value, categories, onFilterChange }) => {
  // This is Common Componet before changing anything need to verify everywhere its using

  const [selectedFilters, setSelectedFilters] = useState<Record<string, (string | number)[]>>(value)
  const [isCategoryOpen, setIsCategoryOpen] = useState<Record<string, boolean>>(
    categories.reduce((acc, category) => ({ ...acc, [category.label]: true }), {})
  )

  const getFilterCount = (filters: Record<string, any>): number => {
    return Object.values(filters).reduce((count, values) => {
      return Array.isArray(values) ? count + values.length : count
    }, 0)
  }

  const handleOptionChange = (category: string, value: string | number) => {
    setSelectedFilters((prevFilters) => {
      const currentValues = prevFilters[category] || []
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value]

      const newFilters = { ...prevFilters, [category]: updatedValues }
      const filterCount = getFilterCount(newFilters)
      onFilterChange(newFilters, filterCount)
      return newFilters
    })
  }

  const clearAllFilters = () => {
    const resetFilters: Record<string, (string | number)[]> = {}
    categories.forEach((category) => {
      resetFilters[category.label] = []
    })
    setSelectedFilters(resetFilters)
    onFilterChange(resetFilters, 0)
  }

  const toggleCategory = (categoryLabel: string) => {
    setIsCategoryOpen((prev) => ({
      ...prev,
      [categoryLabel]: !prev[categoryLabel],
    }))
  }

  return (
    <div className="p-4 bg-app-grey-20 rounded-xl max-h-max overflow-auto shadow w-52">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg text-app-black-100 font-medium">Filters</h3>
        <div className="cursor-pointer">
          <AppBadge
            label={'Clear Filters'}
            size={'sm'}
            color={'Grey'}
            type={'Outline'}
            onClick={clearAllFilters}
          />
        </div>
      </div>

      {categories.map((category, index) => (
        <div key={category.title} className="mb-4">
          <div className="flex items-center justify-between gap-2 mb-2">
            <div className="flex items-center gap-2">
              <h4 className="text-sm text-app-grey-70 font-inter font-normal">{category.title}</h4>
              {category.toolTipText && (
                <AppToolTip name="ToolTip" text={category.toolTipText} variant="primary">
                  <AppToolTipIcon />
                </AppToolTip>
              )}
            </div>
            <div
              onClick={() => toggleCategory(category.label)}
              className="focus:outline-none"
              aria-label={`Toggle ${category.title}`}
            >
              {isCategoryOpen[category.label] ? <AppUpArrowIcon /> : <AppDownArrowIcon />}
            </div>
          </div>
          {isCategoryOpen[category.label] && (
            <div className="space-y-2">
              {category.options.map((option, index) => {
                // Only render the option if it should not be hidden
                if (option.hideOption) return null

                return (
                  <div className="space-y-2" key={option.value}>
                    <div className="flex items-center space-x-2">
                      <AppCheckBox
                        id={option.value.toString()}
                        label={option.label}
                        value={selectedFilters[category.label]?.includes(option.value) || false}
                        onChange={() => handleOptionChange(category.label, option.value)}
                        disabled={category.disabled || option.disabled}
                      />
                      {option.toolTipText && (
                        <AppToolTip name="ToolTip" text={option.toolTipText} variant="primary">
                          <AppToolTipIcon />
                        </AppToolTip>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          <div className="mt-4">{categories.length !== index + 1 && <Divider />}</div>
        </div>
      ))}
    </div>
  )
}
